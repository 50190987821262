import firebase from "firebase/app";
//import the Realtime Database because that's the Firebase service that we will use
import "firebase/database";
//analytics
import "firebase/analytics";
import config from "./env/firebaseConfig.json";

const initFirebase = () => {
    if (!firebase.apps.length) {
        //initialize the app
        firebase.initializeApp(config);
    }
};
initFirebase();

const db = firebase.database();
if (window.location.hostname === "localhost") {
    // Point to the RTDB emulator running on localhost.
    db.useEmulator("localhost", 9000);
}

const analytics = firebase.analytics();
export default firebase;
export {db, analytics};
