import { Switch, Route } from "react-router-dom";
import React from "react";
import "./App.css";
import useGaTracker from "./useGaTracker";
const Home = React.lazy(() => import("./components/Home/Home"));
const Rules = React.lazy(() => import("./components/Rules/Rules"));
const Room = React.lazy(() => import("./components/Room/Room"));
const Game = React.lazy(() => import("./components/Game/Game"));
const Manage = React.lazy(() => import("./components/Manage/Manage"));

const App = () => {
    //Google Analytics
    useGaTracker();
    return (
        <Switch>
            <React.Suspense fallback={<p>Loading</p>}>
                <Route path="/" exact component={Home} />
                <Route path="/rules" exact component={Rules} />
                <Route path="/manage" exact component={Manage} />
                <Route path="/r/:roomLink" exact component={Room} />
                <Route path="/g/:name/:roomLink" exact component={Game} />
            </React.Suspense>
        </Switch>
    );
};

export default App;
