import { analytics } from "./initFirebase";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useGaTracker = () => {
    const location = useLocation();
    //send updated page every time the path changes
    useEffect(() => analytics.setCurrentScreen(location.pathname + location.search), [location]);
};

export default useGaTracker;
